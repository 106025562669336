const Config = {
  datatableFeatures: {
    extraButtons: [
      // {
      //   buttonLabel: 'Upload Settlement',
      //   buttonRef: 'uploadSettlementBtn',
      //   variant: 'info',
      // },
    ],
    tableStatusFilterButton: [
      // {
      //   buttonLabel: 'Pending',
      //   buttonRef: 'pendingSettlementFilter',
      //   active: true,
      // },
      // {
      //   buttonLabel: 'Completed',
      //   buttonRef: 'completedSettlementFilter',
      //   active: false,
      // },
    ],
    tablePaymentProviderFilterButton: [
      // {
      //   // SWITCH_WEBPAY
      //   buttonLabel: 'All',
      //   buttonRef: 'paymentProviderFilterNone',
      //   active: false,
      // },
      // {
      //   // SWITCH_WEBPAY
      //   buttonLabel: 'FPX',
      //   buttonRef: 'paymentProviderFilterWebpay',
      //   active: true,
      // },
      // {
      //   // SWITCH_BNPL
      //   buttonLabel: 'BNPL',
      //   buttonRef: 'paymentProviderFilterBNPL',
      //   active: false,
      // },
      // {
      //   // SWITCH_CC
      //   buttonLabel: 'Credit Card',
      //   buttonRef: 'paymentProviderFilterCC',
      //   active: false,
      // },
      // {
      //   // SWITCH_STRIPE_CC
      //   buttonLabel: 'Stripe',
      //   buttonRef: 'paymentProviderFilterStripe',
      //   active: false,
      // },
      // {
      //   // SWITCH_EWALLET
      //   buttonLabel: 'BoldPay E-wallet',
      //   buttonRef: 'paymentProviderFilterCwallet',
      //   active: false,
      // },
      // {
      //   // SWITCH_EWALLET
      //   buttonLabel: 'SenangPay E-wallet',
      //   buttonRef: 'paymentProviderFilterEwallet',
      //   active: false,
      // },
      // {
      //   // SWITCH_CRYPTOCURRENCY
      //   buttonLabel: 'Cryptocurrency',
      //   buttonRef: 'paymentProviderFilterCrypto',
      //   active: false,
      // },
      // {
      //   // SWITCH_PAYPAL
      //   buttonLabel: 'Paypal',
      //   buttonRef: 'paymentProviderFilterPaypal',
      //   active: false,
      // },
      // {
      //   // SWITCH_STRIPE_CC_EMBED
      //   buttonLabel: 'CC Embed',
      //   buttonRef: 'paymentProviderFilterCCEmbed',
      //   active: false,
      // },

    ],
    buttons: {
      createButton: false,
      createButtonLabel: 'Create New Payout',
      createButtonRoute: 'administration-payout-new',
      refresh: true,
    },
    tableActionField: false,
    datepicker: true,
    showDTDetails: true,
    perPageDropdown: true,
    searchField: false,
    filter: false,
    pagination: true,
    search: true,
  },
  searchOptsDropdown: [
    { value: 'switch_invoice_no', text: 'Switch Invoice ID' },
    { value: 'invoice_no', text: 'Invoice No' },
  ],
  tableData() {
    return {
      tableAction: [
        // CUSTOM
        {
          enable: true, param: 'id', btnLabel: 'Adjust', action: 'custom', btnIcon: '', btnOnly: false, variant: 'warning', size: 'sm', iconSize: '12', actionParam: 'adjustSettlement', disableCondition: 'status:COMPLETED',
        },
        {
          enable: true, param: 'id', btnLabel: 'Disburse', action: 'custom', btnIcon: '', btnOnly: false, variant: 'primary', size: 'sm', iconSize: '12', actionParam: 'disburseSettlement', disableCondition: 'status:COMPLETED',
        },
        // {
        //   enable: true, param: 'settlement_uuid', btnLabel: 'Adjust', action: 'custom', btnIcon: 'SearchIcon', btnOnly: true, variant: 'secondary', size: 'sm', iconSize: '12', actionParam: 'viewDetails', disableCondition: 'status:COMPLETED',
        // },
        // {
        //   enable: true, param: 'settlement_uuid', btnLabel: '', action: 'custom', btnIcon: 'SearchIcon', btnOnly: true, variant: 'secondary', size: 'sm', iconSize: '12', actionParam: 'viewDetails',
        // },
        // {
        //   enable: true, param: 'id', btnLabel: 'Update Status', action: 'custom', btnIcon: '', btnOnly: false, variant: 'info', size: 'sm', iconSize: '12', actionParam: 'openUpdatePayoutStatus',
        // },

        // DEFAULT
        {
          enable: false, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [

        // {
        //   "invoice_no": "Bp-12220BA3C4-Lx",
        //   "customer_bill_id": 2372,
        //   "account_id": "57",
        //   "status": "SUCCESS",
        //   "amount": 100,
        //   "switch_transaction_fee": 0.5,
        //   "is_threshold": true,
        //   "created_at": "2024-06-06T16:45:57",
        //   "item_uuid": "ST-ITEM-3CC89B7E83-LNX",
        //   "id": 16,
        //   "switch_invoice_no": "FPX1717587494e5atwqLk",
        //   "settlement_id": "18c827fe-23af-4857-8824-b1d7d9fe9dd5",
        //   "payment_provider": "SWITCH_WEBPAY",
        //   "transaction_fee": 1.2,
        //   "is_recon": false,
        //   "record_status": 1,
        //   "updated_at": "2024-06-06T16:45:57"
        // }
        // {
        //   "updated_at": "2023-12-28T16:09:52",
        //   "published": null,

        {
          key: 'show_details', label: '', sortable: false, visible: true, type: 'showDetails', class: 'show-details col-width',
        },
        // {
        //   key: 'id', label: 'ID', sortable: true, visible: false,
        // },
        {
          key: 'invoice_no', label: 'Invoice #', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'switch_invoice_no', label: 'Switch Invoice #', sortable: true, visible: true, type: 'string',
        },
        // {
        //   key: 'merchant_name', label: 'Merchant Name', sortable: true, visible: true, type: 'string',
        // },
        // {
        //   key: 'bank_name', label: 'Bank Name', sortable: true, visible: true, type: 'string',
        // },
        // {
        //   key: 'bank_account_number', label: 'Account No', sortable: true, visible: true, type: 'string',
        // },
        // {
        //   key: 'total_revenue', label: 'Revenue', sortable: true, visible: true, type: 'monetary',
        // },
        {
          key: 'invoice_ref', label: 'Invoice Ref', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'payment_provider', label: 'Payment Provider', sortable: true, visible: true, type: 'underscore_string',
        },
        {
          key: 'account_id', label: 'Account ID', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'status', label: 'Status', sortable: true, visible: false, type: 'settlement_status',
        },
        //
        {
          key: 'amount', label: 'Amount', sortable: true, visible: true, type: 'monetary', showInDetails: false,
        },
        {
          key: 'item_uuid', label: 'Item UUID', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'customer_bill_id', label: 'Customer Bill ID', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'transaction_fee', label: 'Txn Fee', sortable: false, visible: true, type: 'monetary', showInDetails: false,
        },
        {
          key: 'switch_transaction_fee', label: 'Switch Txn Fee', sortable: false, visible: true, type: 'monetary', showInDetails: false,
        },
        {
          key: 'is_threshold', label: 'Threshold', sortable: false, visible: true, type: 'boolFlag', showInDetails: false,
        },
        {
          key: 'is_recon', label: 'Recon', sortable: false, visible: true, type: 'boolFlag', showInDetails: false,
        },
        // // {
        // //   key: 'invoice_list', label: 'Invoice List', sortable: false, visible: false, showInDetails: true,
        // // },
        // {
        //   key: 'name', label: 'Name', sortable: true, visible: false, type: 'string', showInDetails: true,
        // },
        // {
        //   key: 'report_id', label: 'Report ID', sortable: true, visible: false, type: 'string', showInDetails: true,
        // },
        // {
        //   key: 'settlement_generated_date', label: 'Generated Date', sortable: true, visible: false, type: 'date', showInDetails: true,
        // },
        // {
        //   key: 'settlement_report_date', label: 'Report Date', sortable: true, visible: false, type: 'date', showInDetails: true,
        // },
        // // {
        // //   key: 'threshold_invoice_list', label: 'Threshold Invoice List', sortable: true, visible: false, type: 'string', showInDetails: true,
        // // },
        // {
        //   key: 'payout_transaction_no', label: 'Payout TXN #', sortable: true, visible: false, type: 'string', showInDetails: true,
        // },
        // {
        //   key: 'settlement_uuid', label: 'Settlement UUID', sortable: true, visible: false, type: 'string', showInDetails: true,
        // },
        // {
        //   key: 'external_settlement_id', label: 'External Settlement ID', sortable: true, visible: false, type: 'string', showInDetails: true,
        // },
        // {
        //   key: 'response', label: 'Response', sortable: true, visible: false, type: 'string', showInDetails: true,
        // },
        //
        {
          key: 'updated_at', label: 'Updated At', sortable: true, visible: true, type: 'date',
        },
        {
          key: 'created_at', label: 'Created At', sortable: true, visible: false, type: 'date', showInDetails: true,
        },
        // {
        //   key: 'actions',
        //   label: 'Action',
        //   class: 'text-right',
        //   visible: true,
        // },
      ],
    }
  },
}

export default Config
