<template>
  <div>
    <b-card>
      <template #header>
        <div class="d-flex justify-content-between">
          <h4 class="mr-1">
            Settlement Detail
          </h4>
          <h4>
            <b-badge
              size="lg"
            >
              {{ humanise(settlementDetail.status) }}
            </b-badge>
          </h4>
        </div>
      </template>
      <b-row>
        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">Merchant Name</label>
            <b-form-input
              size="md"
              plaintext
              :value="settlementDetail.merchant_name"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">Merchant ID</label>
            <b-form-input
              size="md"
              plaintext
              :value="settlementDetail.merchant_id"
            />
          </b-form-group>

        </b-col>
        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">Settlement UUID</label>
            <b-form-input
              size="md"
              plaintext
              :value="settlementDetail.settlement_uuid"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">Payment Provider</label>
            <b-form-input
              size="md"
              plaintext
              :value="settlementDetail.payment_provider"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">External Settlement ID</label>
            <b-form-input
              size="md"
              plaintext
              :value="settlementDetail.external_settlement_id"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">Report ID</label>
            <b-form-input
              size="md"
              plaintext
              :value="settlementDetail.report_id"
            />
          </b-form-group>
        </b-col>

      </b-row>
      <b-row class="border-top pt-2">
        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">Total Outstanding Amount</label>
            <div><vue-numeric
              :value="settlementDetail.total_outstanding_amount"
              :precision="2"
              currency="MYR"
              read-only
            /></div>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">Total Sale</label>

            <div><vue-numeric
              :value="settlementDetail.total_sale"
              :precision="2"
              currency="MYR"
              read-only
            /></div>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">Total Revenue</label>

            <div><vue-numeric
              :value="settlementDetail.total_revenue"
              :precision="2"
              currency="MYR"
              read-only
            /></div>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">Transaction Date</label>
            <b-form-input
              size="md"
              plaintext
              :value="settlementDetail.transaction_date"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">Generated Date</label>
            <b-form-input
              size="md"
              plaintext
              :value="settlementDetail.settlement_report_date"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">Settlement Generated Date</label>
            <b-form-input
              size="md"
              plaintext
              :value="settlementDetail.settlement_generated_date"
            />
          </b-form-group>
        </b-col>

      </b-row>
      <b-row class="border-top pt-2">
        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">Update At</label>
            <b-form-input
              size="md"
              plaintext
              :value="settlementDetail.updated_at"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group>
            <label class="mb-0">Created At</label>
            <b-form-input
              size="md"
              plaintext
              :value="settlementDetail.created_at"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <lp-datatable
      ref="getSettlementRecordItemsRef"
      :extra-parameter="extraParam"
      title="Settlement Item"
      store-module="settlement"
      store-data-endpoint="getSettlementRecordItems"
      store-getter-endpoint="getSettlementRecordItems"
      pagination-state-key="settlementRecordItems"
      :features="loadedConfig.datatableFeatures"
      :load-system-constant="true"
      default-search-field="invoice_no"
      default-sort-field="created_at"
      :search-options-dropdown="loadedConfig.searchOptsDropdown"
      :conf="loadedConfig"
    />
  </div>
</template>

<script>

import Config from '@/views/administration/settlement/settlementDetailformConfig'
import humaniseMixin from '@/common/humanise.mixin'
import LpDatatable from '@/leanpay-components/LPDataTable.vue'
import uploadForm from './Form.vue'
import addSettlementAdjustmentForm from '@/views/administration/settlement/addSettlementAdjustmentForm.vue'

export default {
  components: {
    LpDatatable, uploadForm, addSettlementAdjustmentForm,
  },
  mixins: [humaniseMixin],
  data() {
    return {
      currentRowData: {},
      cardTitle: 'Settlement',
      dataGridEndpoint: [],
      module: 'settlement',
      confirmCheckbox: false,
      inputSwalData: '',
      selectedAdjustmentModalData: {},

      settlementDetail: {
        merchant_name: '',
        settlement_generated_date: '',
        // invoice_list: '[]',
        bank_account_number: '',
        settlement_channel: 1,
        payout_transaction_no: null,
        // threshold_invoice_list: '[]',
        total_sale: 0,
        payment_provider: '',
        created_at: '',
        settlement_uuid: '',
        total_outstanding_amount: 0,
        published: null,
        updated_at: '',
        // id: 0,
        report_id: '',
        total_revenue: 0,
        external_settlement_id: null,
        merchant_id: '',
        status: '',
        transaction_date: '',
        settlement_report_date: '',
      },

      extraParam: {
        settlement_uuid: this.$route.params.uuid,
      },
    }
  },
  computed: {
    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
    settlementOverview() {
      const list = this.$store.getters['settlement/getSettlementOverview']
      if (list) {
        return list
      } return {
        total_outstanding_amount_sum: 0,
        total_revenue_sum: 0,
        total_sale_sum: 0,
      }
    },
  },
  async mounted() {
    // this.isBusy = false
    const breadcrumbUpdatePayload = [
      { title: 'Settlement', route: '/settlement' },
      { title: 'Settlement Detail' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)

    await this.$store.dispatch('settlement/getSettlementOverview')

    const payload = {
      _uuid: this.$route.params.uuid,
    }
    const res = await this.$store.dispatch('settlement/getSettlementDetail', payload)

    if (res.data.response_code === 2000) {
      this.settlementDetail = res.data.data
    }
    // this.initDataGrid()
  },
  methods: {

  },
}
</script>

<style lang="scss">
.leanx-swal {
  .swal2-input-label {
    justify-content: start;
  }

  .swal2-input {
    margin-bottom: 2rem
  }
}
</style>
